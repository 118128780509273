import { COOKIES_OPTIONS } from '@/plugins/inject/cookie'

const ACCESS_TOKEN_KEY = 'stafftoken'

/**
 * 認証プラグイン
 */
export default ({ app }, inject) => {
  inject('auth', {
    // アクセストークンを保存する
    saveAccessToken(token) {
      app.$cookies.set(ACCESS_TOKEN_KEY, token, {
        ...COOKIES_OPTIONS,
        maxAge: 60 * 60 * 24 * 30 * 3, // 3mounth
      })
    },
    // アクセストークンを取得する
    getAccessToken() {
      return app.$cookies.get(ACCESS_TOKEN_KEY)
    },
    // アクセストークンを削除する
    removeAccessToken() {
      app.$cookies.remove(ACCESS_TOKEN_KEY)
    },
    // 認証されているか確認する
    isAuthorized() {
      return Boolean(this.getAccessToken())
    },
  })
}
