
export default {
  name: 'LayoutError',
  props: {
    error: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
}
