
import { mapGetters } from 'vuex'
import 'swiper/css/swiper.css'
import Modal from '@/components/modal/Modal'
import ManualLink from '@/components/links/ManualLink'
import ContactLink from '@/components/links/ContactLink'

export default {
  name: 'ManualModal',
  components: {
    // Modal,
    Modal,
    // マニュアルのリンク
    ManualLink,
    // 問い合わせリンク
    ContactLink,
  },
  data() {
    return {
      swiperOption: {
        centeredSlides: true,
        autoHeight: false,
        loop: false,
        slidesPerView: 1,
        speed: 250,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  props: {
    // モーダルを表示するか
    value: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    ...mapGetters({
      workspace: 'workspace',
    }),
  },
  mounted() {
    this.updateSwiperAutoHeight()
  },
  watch: {
    // ヘッダーがwatchで監視しないと反映しないため
    type() {
      this.updateSwiperAutoHeight()
    },
  },
  methods: {
    onSwiperReady() {
      this.onSlideChange()
    },
    closeModal() {
      this.$emit('close')
    },
    onSlideChange() {
      const element = document.querySelector('.swiper-pagination-bullet-active')
      if (element) {
        element.style.backgroundColor = this.workspace.primary_color
      }
    },
    // imageのみモーダルの高さを可変に
    updateSwiperAutoHeight() {
      if (this.type === 'image') {
        const viewportWidth = window.innerWidth
        this.swiperOption.autoHeight = viewportWidth <= 767
      } else {
        this.swiperOption.autoHeight = false
      }
    },
  },
}
