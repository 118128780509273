export const state = () => ({
  // アラートに表示するメッセージ
  message: null,
  // アラートの種類
  type: null,
  // アラート表示と同時に遷移するページ名
  page: null,
})

export const mutations = {
  alert(state, _) {
    state.message = _.message
    state.type = _.type
    state.page = _.page
  },
  primary(state, _) {
    if (typeof _ === 'string') {
      state.message = _
    } else {
      state.message = _.message
      state.page = _.page
    }
    state.type = 'primary'
  },
  warning(state, _) {
    if (typeof _ === 'string') {
      state.message = _
    } else {
      state.message = _.message
      state.page = _.page
    }
    state.type = 'warning'
  },
  info(state, _) {
    if (typeof _ === 'string') {
      state.message = _
    } else {
      state.message = _.message
      state.page = _.page
    }
    state.type = 'info'
  },
  reset(state) {
    state.message = null
    state.type = null
    state.page = null
  },
}

export const getters = {
  message(state) {
    return state.message
  },
  type(state) {
    return state.type
  },
  page(state) {
    return state.page
  },
}
