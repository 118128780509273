
/**
 * ローディングコンポーネント
 */
export default {
  name: 'LoadingIndex',
  props: {
    size: {
      type: Number,
      required: false,
      default: 100,
    },
    paddingY: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    /**
     * 指定されている場合はその値を使用。未指定の場合は、sizeの1/2をpadding-yに設定
     */
    computedPaddingY() {
      return this.paddingY === null ? this.size * 0.5 : this.paddingY
    },
    loadingStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    },
    loadingWrapStyle() {
      return {
        width: `${this.size}px`,
        padding: `${this.computedPaddingY}px 0`,
      }
    },
  },
}
