import { render, staticRenderFns } from "./ManualModal.vue?vue&type=template&id=16072488&scoped=true"
import script from "./ManualModal.vue?vue&type=script&lang=js"
export * from "./ManualModal.vue?vue&type=script&lang=js"
import style0 from "./ManualModal.vue?vue&type=style&index=0&id=16072488&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16072488",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/codebuild/output/src2182972892/src/riskmill-staff/app/components/modal/Modal.vue').default})
