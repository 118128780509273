import { render, staticRenderFns } from "./HeaderLogo.vue?vue&type=template&id=177cfd23&scoped=true"
import script from "./HeaderLogo.vue?vue&type=script&lang=js"
export * from "./HeaderLogo.vue?vue&type=script&lang=js"
import style0 from "./HeaderLogo.vue?vue&type=style&index=0&id=177cfd23&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "177cfd23",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WorkspaceLogo: require('/codebuild/output/src2182972892/src/riskmill-staff/app/components/workspace/WorkspaceLogo.vue').default})
