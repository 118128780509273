
export default {
  name: 'ManualLink',
  data() {
    // storeからworkspaceの情報を取得
    const workspace = this.$store.state.workspace
    const manualUrl = workspace.manual_url
    return {
      manualUrl,
    }
  },
}
