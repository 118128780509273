
export default {
  name: 'ComponentsAlertbar',
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  data() {
    return {
      isShowAlertbar: this.isShow,
      timeoutId: null,
    }
  },
  watch: {
    isShow(to, from) {
      if (to) {
        this.isShowAlertbar = this.isShow
        this.timeoutId = setTimeout(() => {
          if (this.isShowAlertbar) {
            this.onClose()
          }
        }, 4000)
      }
    },
  },
  methods: {
    onClose() {
      this.timeoutId && window.clearTimeout(this.timeoutId)
      this.$emit('close')
      this.isShowAlertbar = false
    },
  },
}
