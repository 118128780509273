import { COOKIES_OPTIONS } from '@/plugins/inject/cookie'

const MEDIA = 'staff_media'
const DEFAULT_WORD = 'staff_defaultword'
const REDIRECT_URL = 'staff_redirect'

export const state = () => ({
  // 利用するサービス
  service: {
    type: 'crew',
    name: 'crew',
    locale: 'ja',
    favicon: '/favicon_riskmill.ico',
    contactUrl: '#',
    manualUrl: undefined,
    tutorial: 'general',
  },
  workspace: null,
  // 細かな表示出し分けを行うタイプ
  media: undefined,
  // デフォルトワード
  defaultWord: undefined,
  // リダイレクトURL
  redirectUrl: undefined,
  // 案件ID
  eventId: null,
})

export const mutations = {
  setWorkspace(state, workspace) {
    state.workspace = workspace
  },
  media(state, media) {
    state.media = media
    this.$cookies.set(MEDIA, media, COOKIES_OPTIONS)
  },
  defaultWord(state, defaultWord) {
    state.defaultWord = defaultWord
    this.$cookies.set(DEFAULT_WORD, defaultWord, COOKIES_OPTIONS)
  },
  redirectUrl(state, redirectUrl) {
    state.redirectUrl = redirectUrl
    this.$cookies.set(REDIRECT_URL, redirectUrl, COOKIES_OPTIONS)
  },
  eventId(state, eventId) {
    state.eventId = eventId
  },
  initialize(state) {
    state.media = this.$cookies.get(MEDIA)
    state.defaultWord = this.$cookies.get(DEFAULT_WORD)
    state.redirectUrl = this.$cookies.get(REDIRECT_URL)
  },
  reset(state) {
    state.media = null
    state.defaultWord = null
    state.redirectUrl = null
    this.$cookies.remove(MEDIA)
    this.$cookies.remove(DEFAULT_WORD)
    this.$cookies.remove(REDIRECT_URL)
  },
}

export const getters = {
  service(state) {
    return state.service
  },
  workspace(state) {
    return state.workspace
  },
  media(state) {
    return state.media
  },
  defaultWord(state) {
    return state.defaultWord
  },
  redirectUrl(state) {
    return state.redirectUrl
  },
}

export const actions = {
  async fetchWorkspace({ commit }, slug) {
    const res = await this.$axios.$get(`/api/staff/workspaces/${slug}`)
    commit('setWorkspace', res)
    return res
  },
}
