
/**
 * モーダルコンポーネント
 */
export default {
  name: 'ComponentsModal',
  props: {
    // モーダルを表示するか
    value: {
      type: Boolean,
      required: true,
    },
    // クローズボタン表示するか
    isCloseButton: {
      type: Boolean,
      default: true,
    },
    // ミニマムなモーダルにするか
    mini: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
