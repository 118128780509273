import { firebasePresenceCheck } from '@/plugins/inject/firebase'

const SERVICETYPE_KEY = 'rservice'
const IGNORE_CHECK_SERVICETYPE = [
  '/',
  '/logout',
  '/maintenance',
  '/maintenance/ripre',
  '/maintenance/bibin',
  '/e/limituser',
  '/e/mismatch',
  '/e/unauthorized',
  '/sandbox/workspace',
]

/**
 * サービス起動時に呼ばれる初期化ミドルウェア
 */
// context.redirectでredirectされない不具合があるためブラウザのリダイレクトを実行
export default async (context) => {
  if (process.env.APP_ENV !== 'production') {
    console.log('nuxtClientInit')
  }
  const { store, route, params } = context

  // 設定ファイルをstoreに反映
  store.commit('config/set', process.env.CONFIG)

  // ブラウザバックできないようにする
  history.pushState(null, null, location.href)
  window.addEventListener('popstate', (e) => {
    history.go(1)
  })

  // cookieからデータを復元
  store.commit('initialize')

  let serviceType = ''
  if (IGNORE_CHECK_SERVICETYPE.includes(route.fullPath)) {
    // cookieからサービスタイプを取得
    serviceType = context.$cookies.get(SERVICETYPE_KEY)
    if (!serviceType) return
  } else {
    serviceType = params.service || 'default'
    // サービスタイプをcookieに保存
    context.$cookies.set(SERVICETYPE_KEY, serviceType)
  }
  // firebase
  firebasePresenceCheck(serviceType)

  // 未ログインの場合はスキップ
  if (!context.$auth.isAuthorized()) {
    return
  }

  try {
    // ユーザー情報を取得
    await store.dispatch('user/staffMe')
    // URLにexec_idが入っている場合
    if (route.params.id) {
      // exec_idのデータを取得
      await store.dispatch('analysis/detail', route.params.id)
    }
  } catch (error) {
    context.$sentry.captureException(error)
  }
}
