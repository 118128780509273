import { COOKIES_OPTIONS } from '@/plugins/inject/cookie'

const USERID = 'staff_userid'

export const state = () => ({
  staff: null,
  workspace: null,
  // スタッフID
  staffId: null,
  // スタッフ毎の解析実行数
  staffCount: 0,
  // テキスト解析可否
  textAnalysis: false,
  // 画像解析可否
  imageAnalysis: false,
})

export const mutations = {
  set(state, data) {
    state.staff = data.staff
    state.staffId = data.staff.id

    state.workspace = data.staff.workspace
    state.staffCount = data.staff_detect_count

    // 解析の可否
    state.textAnalysis = data.staff.workspace.text_analysis
    state.imageAnalysis = data.staff.workspace.image_analysis
    this.$cookies.set(USERID, data.staff.id, COOKIES_OPTIONS)
  },
  staffId(state, staffId) {
    state.staffId = staffId
  },
  staffCount(state, staffCount) {
    state.staffCount = staffCount
  },
  reset() {
    this.$cookies.remove(USERID)
  },
}

export const getters = {
  staffId(state) {
    return state.staffId
  },
  staffCount(state) {
    return state.staffCount
  },
}

export const actions = {
  /**
   * ユーザー情報API
   */
  async staffMe({ commit }) {
    const res = await this.$axios.$get('/api/staff/me')
    if (res.staff) {
      commit('set', res)
    }
    if (res.staff.workspace) {
      commit('setWorkspace', res.staff.workspace, { root: true })
    }
    return res
  },
}
