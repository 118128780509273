import { render, staticRenderFns } from "./LayoutPageLoadingFull.vue?vue&type=template&id=04580cd2"
import script from "./LayoutPageLoadingFull.vue?vue&type=script&lang=js"
export * from "./LayoutPageLoadingFull.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports