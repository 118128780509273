export const state = () => ({
  // 解析データ
  message: null,
  defaultWord: null,
  media: null,
  eventId: null,
  riskDetails: [],
})

export const actions = {
  /**
   * 新規解析データ作成API
   */
  async create({ state }, { message, media_id, event_id, risk_file_id = undefined }) {
    return await this.$axios.$post('/api/staff/risks', {
      message,
      media_id,
      event_id,
      risk_file_id,
    })
  },
  /**
   * 最新データ保存API
   */
  async update({ state }, { id, message, media_id, event_id, risk_file_id = undefined }) {
    return await this.$axios.$put(`/api/staff/risks/${id}`, {
      message,
      media_id,
      event_id,
      risk_file_id,
    })
  },

  /**
   * 解析実行API
   */
  async detect({ commit }, id) {
    const res = await this.$axios.$post(`/api/staff/risks/${id}/detect`)
    return res
  },

  /**
   * 修正開始API
   */
  async start({ commit }, id) {
    return await this.$axios.$post(`/api/staff/risks/${id}/start`)
  },

  /**
   * 解析途中経過保存API
   */
  async fix({ commit }, { id, message, step, skipped = false }) {
    return await this.$axios.$post(`/api/staff/risks/${id}/fix`, {
      step,
      message,
      skipped,
    })
  },

  /**
   * ロールバックAPI
   */
  async rollback({ commit }, id) {
    return await this.$axios.$post(`/api/staff/risks/${id}/rollback`)
  },

  /**
   * 途中経過確認API
   */
  async confirm({ commit }, { isSet } = { isSet: true }) {
    const res = await this.$axios.$get(`/api/staff/auth/confirm`)
    if (isSet) {
      // データを全てstoreに保存
      commit('set', res)
    }
    return res
  },

  /**
   * 解析詳細取得API
   * @param {boolean} isSet storeに保存するかどうか
   */
  async detail({ commit }, id) {
    const res = await this.$axios.$get(`/api/staff/risks/${id}`)
    commit('riskDetails', res.risk_details)
    commit('message', res.message)
    return res
  },

  /**
   削除API
   */
  async remove({ commit }, id) {
    return await this.$axios.$delete(`/api/staff/risks/${id}`)
  },
}

export const mutations = {
  reset(state) {
    state.message = null
    state.media = null
  },
  message(state, message) {
    state.message = message
  },
  defaultWord(state, defaultWord) {
    state.defaultWord = defaultWord
  },
  media(state, media) {
    state.media = media
  },
  eventId(state, eventId) {
    state.eventId = eventId
  },
  riskDetails(state, riskDetails) {
    state.riskDetails = riskDetails
  },
}

export const getters = {
  message: (state) => state.message,
  defaultWord: (state) => state.defaultWord,
  media: (state) => state.media,
  eventId: (state) => state.eventId,
  riskDetails: (state) => state.riskDetails,
}
