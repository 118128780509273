
import { mapGetters } from 'vuex'
import Alertbar from '@/components/alert/Alertbar'

/**
 * レイアウト上に配置するアラートバー
 */
export default {
  name: 'LayoutAlertbar',
  components: {
    Alertbar,
  },
  data() {
    return {
      isShow: false,
      timeoutId: null,
    }
  },
  computed: {
    ...mapGetters({
      message: 'alert/message',
      page: 'alert/page',
      type: 'alert/type',
    }),
  },
  watch: {
    message() {
      this.isShow = !!this.message
      if (this.isShow) {
        this.isShowAlertbar = true
        this.timeoutId = setTimeout(() => {
          if (this.isShowAlertbar) {
            this.close()
          }
        }, 4000)
      }
    },
    page() {
      if (this.page) {
        if (typeof this.page === 'string') {
          this.$router.push({
            name: this.page,
          })
        } else {
          this.$router.push(this.page)
        }
      }
    },
  },
  methods: {
    close() {
      window.clearTimeout(this.timeoutId)
      this.$store.commit('alert/reset')
    },
  },
}
