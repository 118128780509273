export const state = () => ({
  // 環境変数
  env: null,
  // ベースURL
  baseUrl: null,
  // APIURL
  apiUrl: null,
})

export const mutations = {
  set(state, config) {
    state.env = config.env
    state.baseUrl = config.baseUrl
    state.apiUrl = config.apiUrl
  },
}

export const getters = {
  env(state) {
    return state.env
  },
  baseUrl(state) {
    return state.baseUrl
  },
  apiUrl(state) {
    return state.apiUrl
  },
}
