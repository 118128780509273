import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

dayjs.locale('ja')

function toDayjs(value) {
  return typeof value === 'number' ? dayjs.unix(value) : dayjs(value)
}

/**
 * unixtimeを日付に変換する
 * @param {number}} value unixtime
 * @returns {string} 日付文字列
 */
Vue.filter('formatDate', (value) => {
  if (!value) return value
  return toDayjs(value).format('YYYY.MM.DD')
})

/**
 * unixtimeを日付に変換する
 * @param {number}} value unixtime
 * @returns {string} 日付文字列
 */
Vue.filter('formatTime', (value) => {
  if (!value) return value
  return toDayjs(value).format('HH:mm')
})

/**
 * unixtimeを日付に変換する
 * @param {number}} value unixtime
 * @returns {string} 日付文字列
 */
Vue.filter('formatDateTime', (value) => {
  if (!value) return value
  return toDayjs(value).format('YYYY.MM.DD HH:mm')
})

/**
 * unixtimeを日付に変換する
 * @param {number}} value unixtime
 * @returns {string} 日付文字列
 */
Vue.filter('formatDateTimeSec', (value) => {
  if (!value) return value
  return toDayjs(value).format('YYYY.MM.DD HH.mm.ss')
})

/**
 * 残り時間を表示する
 */
Vue.filter('toRemainingDate', (value) => {
  if (!value) return value

  // 現在時間
  const currentTime = dayjs()
  // 表示対象日時
  const targetTime = dayjs.unix(value)

  let second = targetTime.endOf('day').diff(currentTime, 'second')
  const day = Math.floor(second / 24 / 60 / 60)
  second -= day * 24 * 60 * 60
  const hour = Math.floor(second / 60 / 60)
  second -= hour * 60 * 60
  const min = Math.floor(second / 60)
  second -= min * 60

  // ~23時間59分59秒まで
  if (targetTime.diff(currentTime, 'hour') < 24) {
    return `${min}分 ${second}秒`
  } else {
    return `${day}日 と ${hour}時間`
  }
})

/**
 * 相対的な日時を表示する
 * @param {number}} value unixtime
 * @returns {string}
 */
Vue.filter('toRelativeDateTime', (value) => {
  if (!value) return value

  // 現在時間
  const currentTime = dayjs()
  // 表示対象日時
  const targetTime = dayjs.unix(value)

  // 現在時間~ (不具合がある場合)
  if (currentTime.diff(targetTime, 'second') < 0) {
    return `0秒前`
  }

  // ~59秒まで
  if (currentTime.diff(targetTime, 'second') < 60) {
    return `${currentTime.diff(targetTime, 'second')}秒前`
  }

  // ~59分59秒まで
  if (currentTime.diff(targetTime, 'minute') < 60) {
    return `${currentTime.diff(targetTime, 'minute')}分前`
  }

  // ~23時間59分59秒まで
  if (currentTime.diff(targetTime, 'hour') < 24) {
    return `${currentTime.diff(targetTime, 'hour')}時間前`
  }

  // ~30日23時間59分59秒まで
  if (currentTime.diff(targetTime, 'day') < 31) {
    return `${currentTime.diff(targetTime, 'day')}日前`
  }

  // ~12ヶ月間直前まで
  if (currentTime.diff(targetTime, 'month') < 12) {
    // 31日離れていても1ヶ月差分があるかどうかわからないので0ヶ月の場合は1ヶ月と表示する
    return `${
      currentTime.diff(targetTime, 'month') === 0 ? 1 : currentTime.diff(targetTime, 'month')
    }ヶ月前`
  }

  // 12ヶ月前~
  return targetTime.format('YYYY年MM月DD日')
})
