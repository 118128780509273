
import { mapGetters } from 'vuex'
import PageLoadingFull from '@/components/loading/PageLoadingFull'

/**
 * レイアウトに配置する全画面ページローディング
 */
export default {
  name: 'LayoutPageLoadingFull',
  components: {
    PageLoadingFull,
  },
  computed: {
    ...mapGetters({
      isLoading: 'loading/isLoading',
    }),
  },
}
