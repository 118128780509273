export default ({ $axios, app, store, redirect, error, $sentry }) => {
  $axios.onRequest((config) => {
    const isInternal = !config.url.startsWith('http')
    const accessToken = app.$auth.getAccessToken()
    if (isInternal && accessToken) {
      config.headers.common.authorization = `Bearer ${accessToken}`
    }
    return config
  })

  $axios.onError((err) => {
    // 401, 422, 400以外のエラーはSentryに送信
    if (err.response.status !== 401 && err.response.status !== 422 && err.response.status !== 400) {
      $sentry.captureException(err)
    }
    if (err.response.status === 400) {
      throw error({ statusCode: 400, message: err.response.data.message })
    }
    if (err.response.status === 500) {
      throw error({ statusCode: 500, message: 'Internal Server Error' })
    }
    if (err.response.status === 503) {
      throw error({ statusCode: 503, message: 'メンテナンス中です' })
    }

    return Promise.reject(err)
  })
}
