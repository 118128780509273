import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const { Quill } = VueQuillEditor

const Clipboard = Quill.import('modules/clipboard')
const History = Quill.import('modules/history')

class PlainTextClipboard extends Clipboard {
  constructor(quill, options) {
    super(quill, options)
    this.quill.root.addEventListener('copy', (e) => this.onCaptureCopy(e, false))
    this.quill.root.addEventListener('cut', (e) => this.onCaptureCopy(e, true))
  }

  onPaste(e) {
    e.preventDefault()

    const clipboardData = e.clipboardData || window.clipboardData
    const plainText = clipboardData.getData('text/plain')

    const index = this.quill.getSelection().index
    this.quill.insertText(index, plainText, Quill.sources.USER)
  }

  // 現行バージョンより一つ上の最新バージョンのコードを参考に実装
  // https://github.com/quilljs/quill/blob/v2.0.0-dev.0/modules/clipboard.js#L127
  onCaptureCopy(e, isCut = false) {
    if (e.defaultPrevented) {
      return
    }

    e.preventDefault()

    const [range] = this.quill.selection.getRange()
    if (range === null) {
      return
    }

    const text = this.quill.getText(range)
    e.clipboardData?.setData('text/plain', text)

    if (isCut) {
      this.quill.deleteText(range.index, range.length, Quill.sources.SILENT)
    }
  }
}

class CustomizeHistory extends History {
  undo() {
    super.undo()
    this.quill.emitter.emit('undo')
  }

  redo() {
    super.redo()
    this.quill.emitter.emit('redo')
  }
}

Quill.register('modules/clipboard', PlainTextClipboard)
Quill.register('modules/history', CustomizeHistory)

const option = {
  modules: {
    toolbar: false,
    history: true,
    keyboard: true,
    clipboard: true,
  },
  placeholder: '',
}

Vue.use(VueQuillEditor, option)
