/**
 * 緊急対応
 * 特定のサービスを強制的にメンテナンスモードにする
 */
// const LIST = ['/', '/maintenance2', '/workspace', '/logout']
export default function (context) {
  //   if (
  //     LIST.includes(context.route.path) === false &&
  //     context.store.state.workspace &&
  //     context.store.state.workspace.slug === 'rabep'
  //   ) {
  //     // context.redirect('/maintenance2')
  //   }
}
