
import Loading from '@/components/loading/Loading'

/**
 * 全画面ページローディング
 */
export default {
  name: 'PageLoadingFull',
  components: {
    Loading,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isInnerLoading: false,
    startTimeoutId: null,
  }),
  methods: {
    start() {
      this.startTimeoutId = setTimeout(() => {
        this.startTimeoutId = null
        this.isInnerLoading = true
      }, 100)
    },
    finish() {
      if (this.startTimeoutId) {
        clearTimeout(this.startTimeoutId)
      }

      setTimeout(() => {
        this.isInnerLoading = false
      }, 100)
    },
  },
}
