
import WorkspaceLogo from '@/components/workspace/WorkspaceLogo.vue'
export default {
  components: {
    WorkspaceLogo,
  },
  data() {
    return {
      workspace: this.$store.state.workspace,
    }
  },
}
