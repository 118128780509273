import { render, staticRenderFns } from "./analysis.vue?vue&type=template&id=6836fabc&scoped=true"
import script from "./analysis.vue?vue&type=script&lang=js"
export * from "./analysis.vue?vue&type=script&lang=js"
import style0 from "./analysis.vue?vue&type=style&index=0&id=6836fabc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6836fabc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderLogo: require('/codebuild/output/src2182972892/src/riskmill-staff/app/components/header/HeaderLogo.vue').default,Progress: require('/codebuild/output/src2182972892/src/riskmill-staff/app/components/Progress.vue').default})
