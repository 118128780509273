import { initializeApp } from 'firebase/app'
import * as database from 'firebase/database'

const app = initializeApp(process.env.CONFIG.firebase)
const db = database.getDatabase(app)

export default (context, inject) => {
  inject('firebase', {
    app,
    db,
  })
}

/**
 * 接続最大ユーザー数
 * serviceType毎に反映される
 */
const LIMIT_USER_COUNT = 80

/**
 * Firebaseプレゼンス機能
 * @see https://cloud.google.com/firestore/docs/solutions/presence?hl=ja
 */
export function firebasePresenceCheck(serviceType = 'default') {
  const userConnectionsRef = database.ref(db, `/connections/${serviceType}`)
  const connectedInfoRef = database.ref(db, '.info/connected')

  let currentRef
  database.onValue(connectedInfoRef, async (snapshot) => {
    if (!snapshot.val()) {
      return
    }

    if (!currentRef) {
      currentRef = database.push(userConnectionsRef)
    }

    await database.onDisconnect(currentRef).remove()
    await database.set(currentRef, true)
  })

  /**
   * 接続ユーザー数を取得
   * 想定ユーザー数より多かったらエラーページにリダイレクト (一度のみ実行)
   */
  database.onValue(
    userConnectionsRef,
    (snapshot) => {
      if (!snapshot.val()) {
        return
      }
      const userCount = Object.keys(snapshot.val()).length
      if (userCount > LIMIT_USER_COUNT) {
        // window.location.href = `/e/limituser`
      }
    },
    {
      onlyOnce: true,
    }
  )
}
