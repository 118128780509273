import Vue from 'vue'

/**
 * 単位付きで表示
 * @param {number} value
 * @return {string}
 */
Vue.filter('withUnit', (value) => {
  const digitsNumber = ('' + value).length
  if (digitsNumber >= 7) {
    return `${Math.round(value / 100000) / 10}m`
  }
  if (digitsNumber >= 4) {
    return `${Math.round(value / 100) / 10}k`
  }
  return value
})

/**
 * 数字の場合のみ表示する。数字ではない場合は0にする。
 * @param {any} value
 * @return {string}
 */
Vue.filter('numberOnly', (value) => {
  return Number.isInteger(Number(value)) && value > 0 ? value : 0
})

/**
 * 3桁のカンマ区切りにする
 */
Vue.filter('numberFormat', (value) => {
  if (!value) return value
  if (typeof value === 'number') {
    const formatter = new Intl.NumberFormat('ja-JP')
    return formatter.format(value)
  }
  return value
})
