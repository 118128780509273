import Vue from 'vue'

// import
import './date'
import './number'

// methods
Vue.filter('notEmpty', (value) => {
  return value || ''
})
