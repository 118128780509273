
export default {
  data() {
    const workspace = this.$store.state.workspace
    return {
      workspace,
      contactUrl: workspace.contact_url,
      isShowMenu: false,
    }
  },
  methods: {
    toggleMenu() {
      this.isShowMenu = !this.isShowMenu
    },
    closeMenu() {
      this.isShowMenu = false
    },
  },
}
