
export default {
  name: 'RiskProgress',
  props: {
    no: {
      type: Number,
      required: true,
    },
    riskNum: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isShowProgressStatus: true,
    }
  },
  computed: {
    progressWidth() {
      // 0除算対応
      if (!this.riskNum) {
        return 100
      }
      const width = Math.ceil(100 / this.riskNum) * this.no
      return width >= 100 ? 100 : width
    },
  },
  watch: {
    no() {
      this.isShowProgressStatus = false
      this.$nextTick(() => {
        this.isShowProgressStatus = true
      })
    },
  },
  methods: {},
}
