
import { mapGetters } from 'vuex'
import Progress from '@/components/Progress'
import HeaderLogo from '@/components/header/HeaderLogo'
import LayoutAlertbar from '@/components/alert/LayoutAlertbar'
import LayoutPageLoadingFull from '@/components/loading/LayoutPageLoadingFull'
import ManualModal from '@/components/modal/ManualModal'
import GlobalNav from '@/components/layout/analysis/GlobalNav'

export default {
  name: 'LayoutAnalytics',
  components: {
    Progress,
    LayoutAlertbar,
    LayoutPageLoadingFull,
    HeaderLogo,
    ManualModal,
    GlobalNav,
  },
  transition: 'fade',
  data({ $route }) {
    const { name } = $route
    const step = $route.params.no
    // 自分のワークスペース名からサービスを取得
    return {
      id: $route.params.id,
      isEditPage: name.includes('modify'),
      isTopPage: name === 'rabep-analysis',
      isCompletePage: name === 'rabep-analysis-complete',
      isSummary: name === 'rabep-analysis-summary' ? true : null,
      // 解析の修正番号
      step: step ? Number(step) : null,
      isMenuOpen: false,
      isHowto: false,
      // グロナビ内リンクの遷移先格納用
      destination: '',
    }
  },
  computed: {
    ...mapGetters({
      workspace: 'workspace',
      riskDetails: 'analysis/riskDetails',
    }),
  },
  watch: {
    $route(to, from) {
      this.isEditPage = to.name.includes('modify')
      if (from.params.no !== to.params.no) {
        const { no } = to.params
        this.step = no === undefined ? null : Number(no)
      }

      window.scrollTo(0, 0)
    },
  },
  methods: {
    // メニューを開閉する
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    resetSwiper() {
      this.$nextTick(() => {
        this.isFirstSlide = true
        this.isLastSlide = false
      })
    },
    closeModal() {
      this.isHowto = false
      this.resetSwiper()
    },
  },
  head() {
    return {
      link: [{ rel: 'icon', type: 'image/x-icon', href: this.$store.state.workspace.favicon }],
    }
  },
}
