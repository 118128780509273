// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/header/inquiry3.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button[data-v-d35b3988]{border:1px solid #dbdee4;border-radius:6px;height:38px;width:166px!important}.button a[data-v-d35b3988]{color:#2a3256;display:inline-block;font-size:14px;font-weight:500;line-height:38px;padding:0 13px;text-align:right;width:100%}@media(min-width:768px){.button a[data-v-d35b3988]:hover{opacity:.7;transition:all .5s ease 0s}}.button a.is-current[data-v-d35b3988],.button a[data-v-d35b3988]:active{opacity:.7;transition:all .5s ease 0s}.button a span[data-v-d35b3988]{position:relative}.button a span[data-v-d35b3988]:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;content:\"\";display:block;height:24px;left:-29px;position:absolute;top:50%;transform:translateY(-50%);width:22px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
