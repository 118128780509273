let version
/**
 * ソースコードが最新かチェックする
 */
export default function (context) {
  fetch('/version.txt').then(async (res) => {
    if (!res.ok) {
      context.$sentry.captureException(new Error(`can't get version.txt: ${res.status}`))
      return
    }
    const text = await res.text()
    if (!version) {
      version = text
      return
    }
    if (version !== text) {
      // ページをリロード
      window.location.reload()
    }
  })
}
