
import LayoutAlertbar from '@/components/alert/LayoutAlertbar'
import LayoutPageLoadingFull from '@/components/loading/LayoutPageLoadingFull'

/**
 * ログインレイアウト
 */
export default {
  name: 'LayoutLogin',
  components: {
    LayoutAlertbar,
    LayoutPageLoadingFull,
  },
  head() {
    return {
      link: [{ rel: 'icon', type: 'image/x-icon', href: this.$store.state.workspace.favicon }],
    }
  },
}
